function parseFlow(data) {
    if (!data) {
        return '0byte';
    }
    let unitIndex = 0;
    let unitArr = ["byte", "KB", "MB", "GB", "TB"];
    while (data > 1024 && unitIndex < unitArr.length) {
        unitIndex++;
        data = data / 1024;
    }
    return data.toFixed(2) + unitArr[unitIndex];
}


export default parseFlow;